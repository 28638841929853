import { remoteAssists, videoCalling } from '@hpx-it/developer-api-client';
import { logger, useInterval } from '@hpx-it/react-app';
import { DeveloperApiClientContext } from 'contexts/developerApiClientContext';
import jwtDecode from 'jwt-decode';
import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const { REACT_APP_REMOTE_ASSIST_POLL_MS } = process.env;

type RemoteAssist = remoteAssists.RemoteAssist;
type ParticipantToken = videoCalling.GetParticipantTokenResponse;
type RemoteAssistContextProps = {
  remoteAssistId: string;
  remoteAssist: RemoteAssist | undefined;
  participantToken: ParticipantToken | undefined;
  error: boolean;
  getRemoteAssist: () => Promise<void>;
  cancelRemoteAssist: () => Promise<void>;
  createParticipantToken: () => Promise<string | undefined>;
  termsAccepted: boolean;
  setTermsAccepted: Dispatch<React.SetStateAction<boolean>>;
};

export type RemoteAssistProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: RemoteAssistContextProps = {
  remoteAssistId: '',
  remoteAssist: undefined,
  participantToken: undefined,
  error: false,
  getRemoteAssist: async () => {},
  cancelRemoteAssist: async () => {},
  createParticipantToken: async () => {
    return undefined;
  },
  termsAccepted: false,
  setTermsAccepted: () => {},
};

export const RemoteAssistContext =
  createContext<RemoteAssistContextProps>(DEFAULT_CONTEXT);

export const RemoteAssistProvider = ({
  children,
}: RemoteAssistProviderProps) => {
  const { getDeveloperApiClient, token } = useContext(
    DeveloperApiClientContext,
  );
  const [remoteAssist, setRemoteAssist] = useState<RemoteAssist>();
  const [participantToken, setParticipantToken] = useState<ParticipantToken>();
  const [error, setError] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const remote_assist_id = useMemo(() => {
    if (!token) {
      logger.warn('No token found');
      return '';
    }
    try {
      const { remote_assist_id }: { remote_assist_id: string } =
        jwtDecode(token);
      logger.addContext('remote_assist_id', remote_assist_id);
      logger.info('Token decoded', { remote_assist_id });
      return remote_assist_id;
    } catch (err) {
      logger.warn('Invalid token', { token }, err as Error);
      return '';
    }
  }, [token]);

  const getRemoteAssist = useCallback(async () => {
    if (!token) {
      return;
    }

    const remoteAssist =
      await getDeveloperApiClient().getRemoteAssistWithSignedLink();

    if (!remoteAssist) {
      setError(true);
      return;
    }
    logger.addContext('client', remoteAssist.client.id);
    setRemoteAssist(remoteAssist);
  }, [getDeveloperApiClient, token]);

  const cancelRemoteAssist = useCallback(async () => {
    logger.info('User attempting to cancel remote assist');

    try {
      await getDeveloperApiClient().cancelRemoteAssistWithSignedLink({
        canceled_reason: 'canceled',
      });
      logger.info('User successfully cancelled remote assist');
    } catch {
      setError(true);
    }
  }, [getDeveloperApiClient]);

  const createParticipantToken = useCallback(async () => {
    const token = await getDeveloperApiClient().getParticipantToken();

    if (!token) {
      setError(true);
      return;
    }

    setParticipantToken(token);
    return token.token;
  }, [getDeveloperApiClient]);

  useEffect(() => {
    getRemoteAssist();
  }, [getRemoteAssist, token]);

  // keep on polling for remote assist so we can update if the status is completed / cancelled
  useInterval(() => {
    getRemoteAssist();
  }, Number(REACT_APP_REMOTE_ASSIST_POLL_MS));

  return (
    <RemoteAssistContext.Provider
      value={{
        remoteAssistId: remote_assist_id,
        remoteAssist,
        participantToken,
        error,
        getRemoteAssist,
        cancelRemoteAssist,
        createParticipantToken,
        termsAccepted,
        setTermsAccepted,
      }}
    >
      {children}
    </RemoteAssistContext.Provider>
  );
};
